function Footer() {
  return (
    <section className="grid">
      <div className="grid mobile:flex     mobile:h-full  mb-20	 w-full h-5/6 md:grid-cols-1   place-items-center">
        <div className="grid  w-4/6 mobile:w-full  text-center	 just mobile:gap-4   ">
          <h2 className="text-md mobile:text-xs mx-auto   xl:text-md 2xl:text-md">
            {" "}
            Designed & Built by Ricci Dylan Andrada
          </h2>
        </div>
      </div>
    </section>
  );
}

export default Footer;
