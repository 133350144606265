function Contact() {
  return (
    <section className="grid  h-4/6	 ">
      <div className="grid mobile:flex     mobile:h-full	 w-full h-2/6 md:grid-cols-1   place-items-center">
      <div className="grid  w-4/6 mobile:w-full 	 just mobile:gap-4   ">
      <div className="text-center" >
            <h2 className="text-2xl  mx-auto  w-auto inline-block  mobile:text-2xl xl:text-4xl 2xl:text-4xl">
              {" "}
              Get in Touch
              <div className="divider mx-auto  bg-orange h-px  2xl:w-28 "></div>

            </h2>
          </div>

          <p className="text-xl w-4/6  mx-auto mobile:text-center mobile:text-xl xl:text-xl 2xl:text-xl">
            I am always looking for new connections. Whether you have questions
            or just want to say hi
          </p>
          <a
            className=" btn hover:bg-orange btn-success mx-auto w-24 border-orange bg-background text-xs"
            href="mailto:email@email.de"
          >
            Say Hello
          </a>
        </div>
      </div>
    </section>
  );
}

export default Contact;
