import { useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function FadeInWhenVisible({ children }) {
    const { ref, inView } = useInView();
    useEffect(() => {}, [inView]);
    return (
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
        variants={{
          visible: { opacity: 1, scale: 1 },
          hidden: { opacity: 0, scale: 0 },
        }}
      >
       <>
       {children}
       </>
      </motion.div>
    );
  }
  export default FadeInWhenVisible