function AboutMe() {
  return (
    <section className="grid  ">
      <div className="grid mobile:flex     mobile:h-full	 w-full h-2/6 md:grid-cols-1 mobile:flex-col-reverse  place-items-center">
        <div className="grid  w-4/6 mobile:w-full 	 just mobile:gap-4   ">
        <div className="mobile:text-center" >
        <h2  className="text-2xl  mobile:mx-auto w-auto inline-block  mobile:text-2xl xl:text-2xl 2xl:text-2xl">
            About Me
            <div className="divider mobile:mx-auto   bg-orange  h-px  2xl:w-28 "/>

          </h2>
        </div>
          <div className="mx-auto text-left  w-5/6 ">
            <h2 className="text-xl  mobile:text-sm 2xl:text-xl">
              My name is Ricci Dylan Andrada. I graduated from New Jersey
              Institute of Technology with a degree in computer science. I enjoy
              creating and designing applications that solve real-life problems.
            </h2>
            <br />
            <h2 className="text-xl mobile:text-sm 2xl:text-xl">
              Currently I am working in ADP as an Associate Application
              Developer developing on microfrontends and microservices with the
              MERN stack.
            </h2>
            <br />

            <h2 className="text-xl mobile:text-sm 2xl:text-xl">
              I have always loved learning new things, and being challenged.
              Which is why the field of computer science attracted me so much as
              there is always something new to learn and problems to be solve. I
              strive to improve my skills and knowledge everyday.
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutMe;
